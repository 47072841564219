html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root, .playlist-list {
  display: flex;
  margin: 0;
  flex-flow: column;
  height: 100%;
}

.carousel-root {
  display: flex;
  flex: 1;
}

.carousel .slide img {
  width: 80vmin;
  height: 80vmin;
}

.list-item {
  flex-flow: column;
  display: flex;
}

h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4vmin;
  padding: 1vmin;
  padding-top: 2.5vmin;
  padding-bottom: 2.5vmin;
  margin: 0;
}

p {
  margin-left: 5vmin;
  margin-top: 1vmin;
  margin-bottom: 1vmin;
}

.carousel .slide {
  background: #FFF;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #000;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #000;
}

.control-dots {
  padding-inline-start: 0px;
}

.player {
}